import React, { useContext, useRef } from "react";
import { DataContext } from "../../../contexts/DataContext";
import {
  StyledBeeSectionTitle,
  StyledWrapperLinkItem,
  StyledLinkItem,
  LinksWrapper,
  StyledWrapperSingleItem,
} from "./style.jsx";
import { fadeLinkOnBottom, fadeOnBottom, staggerChildren } from "../animations";
import loadable from "@loadable/component";

const ExpandingLine = loadable(props => import("../ExpandingLine/ExpandingLine"), {
  ssr: false,
});

export default () => {
  const { pageLabels, pageImages } = useContext(DataContext);

  const apechipstyle = pageImages.find(
    (e) => e.path === "/2020/images/apechipstyle.jpg",
  );

  const containerRef = useRef();

  const getParentElement = () => {
    return containerRef;
  };

  return (
    <LinksWrapper
      ref={containerRef}
      src={apechipstyle.path}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      variants={staggerChildren}
    >
      <ExpandingLine
        parentElement={getParentElement}
        topDot="filled"
        bottomDot="outlined"
        height="194px"
        top="-120px"
        offset={["-100vh", "-40vh"]}
      />
      <StyledBeeSectionTitle
        dangerouslySetInnerHTML={{ __html: pageLabels.linksSection.title }}
        variants={fadeOnBottom}
      />
      <StyledWrapperLinkItem>
        {pageLabels.linksSection.containsList.map((item, index) => (
          <StyledWrapperSingleItem key={index} variants={fadeLinkOnBottom}>
            <StyledLinkItem href={`#${item.id}`}>
              {item.label}
            </StyledLinkItem>
          </StyledWrapperSingleItem>
        ))}
      </StyledWrapperLinkItem>
    </LinksWrapper>
  );
};
