import styled from "styled-components";
import { BP, Font, Rem } from "../../../commons/Theme";

export const StyledVideoSectionContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  padding: 0 ${Rem(20)};

  text-align: center;

  margin-bottom: ${Rem(64)};

  @media (${BP.tablet}) {
    max-width: 60%;
  }
`;

export const StyledVideoSectionTitle = styled.h2`
  font-family: 'Vollkorn';
  font-weight: 700;
  font-size: ${Rem(32)};
  line-height: ${Rem(45)};

  margin-bottom: ${Rem(16)};

  @media (${BP.tablet}) {
    font-size: ${Rem(40)};
    line-height: ${Rem(56)};
  }
`;

export const StyledVideoSectionDescription = styled.p`
  font-family: ${Font.sans};
  font-weight: 700;

  font-size: ${Rem(16)};
  line-height: ${Rem(20)};
  letter-spacing: 3px;

  margin-bottom: ${Rem(24)};

  @media (${BP.tablet}) {
    font-size: ${Rem(24)};
    line-height: ${Rem(30)};

    margin-bottom: ${Rem(64)};
  }
`;
