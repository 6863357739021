import styled from "styled-components";
import { Rem, Colors, BP } from "../../../commons/Theme";
import { motion } from "framer-motion";

export const TechsWrapper = styled(motion.div)`
  position: relative;

  display: flex;
  flex-direction: column;

  color: ${Colors.white};
  background-color: transparent;

  padding: ${Rem(60)} 0;

  & > :nth-child(1) {
    order: 1;
  }

  & > :nth-child(2) {
    order: 3;
  }

  & > :nth-child(3) {
    order: 2;
  }

  @media (${BP.tablet}) {
    padding: ${Rem(70)} 0;

    & > :nth-child(2) {
      order: 2;
    }

    & > :nth-child(3) {
      order: 3;
    }
  }
`;

export const StyledBeeSectionTitle = styled(motion.h1)`
  font-family: 'Vollkorn';
  font-size: ${Rem(30)};
  padding: 0 ${Rem(20)};
  @media (${BP.tablet}) {
    text-align: center;
    font-size: ${Rem(40)};
    padding: ${Rem(20)} ${Rem(150)};
  }
`;

export const StyledTechnologySectionSubTitle = styled(motion.h2)`
  font-size: ${Rem(20)};
  padding: 0 ${Rem(20)};
  margin-top: ${Rem(32)};

  @media (${BP.tablet}) {
    text-align: center;
    font-size: ${Rem(25)};

    padding: ${Rem(60)} ${Rem(150)};
    margin-top: ${Rem(0)};
  }
`;

export const StyledWrapperSwiper = styled.div`
  padding: ${Rem(30)} ${Rem(0)};
  .swiper-slide {
    transition: all 0.2s ease-out 0s;
  }
  .swiper-slide-active {
    transform: scale(1.5);
  }
  .swiper-slide-next,
  .swiper-slide-prev {
    transform: scale(0.85);
  }
  @media (${BP.tablet}) {
    padding: ${Rem(30)} ${Rem(60)};
    .swiper-slide {
      transition: all 0.5s ease-out 0s;
    }
    .swiper-slide-active {
      transform: scale(1.2);
    }
    .swiper-slide-next,
    .swiper-slide-prev {
      transform: scale(0.9);
    }
  }
`;

export const StyledWrapperImage = styled(motion.div)`
  margin: auto;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    @media (${BP.tablet}) {
      aspect-ratio: 4/3;
    }
  }
`;
