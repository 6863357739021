import { Button } from "@3beehivetech/components";
import styled from "styled-components";
import {
  BP, Colors, Font, Rem, 
} from "../../../commons/Theme";
import { StyledTitle, StyledBigText } from "../../../commons/Typography";
import { motion } from "framer-motion";

export const StyledWrapper = styled(motion.div)`
  width: 100%;
  height: 110vh;
  position: relative;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 20.57%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(0deg, rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 0.7));
  background-size: cover;
  color: ${Colors.white};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > :nth-child(2) {
    margin-bottom: ${Rem(30)};
  }

  & > :nth-child(3) {
    margin-bottom: ${Rem(16)};
  }

  & > :nth-child(5) {
    margin-bottom: ${Rem(64)};
  }
`;

export const StyledWrapperVideo = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
  }
`;

export const StyledGradient = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 20.57%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(0deg, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.5));
`;

export const StyledFlower = styled(motion.div)`
  background-image: url("/originals/svg/flower.svg");
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 4rem;
  height: 8rem;
  z-index: 3;
  position: absolute;
  top: -20px;
  right: ${Rem(64)};

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 38px;

    width: 2px;
    height: ${Rem(70)};

    background-color: ${Colors.yellow};
  }
  @media (${BP.tablet}) {
    position: relative;
    top: -20px;
    right: auto;

    height: 11rem;

    &::after {
      top: 6px;

      height: 7rem;
    }
  }
`;

export const StyledOriginalsTitle = styled(motion(StyledTitle))`
  color: ${Colors.yellow};
  font-weight: 100;
  font-family: ${Font.sans};
  font-size: ${Rem(32)};
  line-height: ${Rem(40)};
  letter-spacing: 3px;
  z-index: 3;
  & > strong {
    font-weight: 900;
  }
`;

export const StyledOriginalsSubtitleHero = styled(motion(StyledTitle))`
  font-weight: 700;
  font-family: ${Font.sans};
  font-size: ${Rem(32)};
  line-height: ${Rem(40)};
  letter-spacing: 3px;
  z-index: 3;
  text-transform: capitalize;
  text-align: center;

  @media (${BP.tablet}) {
    text-align: initial;
  }
`;

export const StyledWrapperButton = styled(motion.div)`
  z-index: 3;
`;

export const StyledHeroCta = styled(Button)`
  padding: ${Rem(16)} ${Rem(137)} !important;
`;

export const MotionBigText = styled(motion(StyledBigText))`
  font-family: 'Vollkorn';
  text-align: center;
  font-weight: 700;
  font-size: ${Rem(40)};
  line-height: ${Rem(46)};
  z-index: 3;
  @media (${BP.tablet}) {
    font-size: ${Rem(80)};
    line-height: ${Rem(111)};

    padding: ${Rem(16)} ${Rem(137)} !important;
  }
`;
