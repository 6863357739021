import styled from "styled-components";
import { Colors, Rem, BP } from "../../../commons/Theme";
import { motion } from "framer-motion";

const getTextColor = (isDarkBackground) =>
  isDarkBackground ? Colors.white : Colors.black;

const getBackgroundColor = (background) =>
background ? Colors.black : Colors.white;

export const StyledWrapperComponents = styled.div`
  background-color: ${({ background }) => getBackgroundColor(background)};
  transition: all 0.65s ease-out;
`;
export const StyledGradient = styled(motion.div)`
  background: linear-gradient(180deg,#111111, transparent);
  height: 250px;
  width: 100%;
`;

export const StyledPreTech = styled(motion.h2)`
  color: ${({ background }) => getTextColor(background)};
  padding: 0 ${Rem(20)};
  font-size: ${Rem(20)};
  padding: ${Rem(70)} 0;
  letter-spacing: 3px;
  @media (${BP.tablet}) {
    text-align: center;
    padding: ${Rem(180)} ${Rem(270)};
    padding-bottom: ${Rem(32)};
  }
`;

