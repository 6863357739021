import React, { useState, useContext } from "react";

import { DataContext } from "../contexts/DataContext";
import { LayoutContext } from "../contexts/LayoutContext";

import BeeSection from "../components/Originals/BeeSection/BeeSection";
import LinksSection from "../components/Originals/LinksSection/LinksSection";
import WrapperBackGroundChanging from "../components/Originals/WrapperBackGroundChanging/WrapperBackGroundChanging";
import Hero from "../components/Originals/Hero/Hero";
import GridSection from "../components/Originals/GridSection/GridSection";
import ActNowModal from "../components/Originals/ActNowModal/ActNowModal";
import VideoSection from "../components/Originals/VideoSection/VideoSection";
import BottomBeeSection from "../components/Originals/BottomBeeSection/BottomBeeSection";
import Meta from "../components/Meta/Meta";

const Originals = () => {
  const {
    pageLabels,
    // currentLanguageSuffix,
    pageImages,
    // i18nLink,
    // labels,
  } = useContext(DataContext);
  const [background, setBackground] = useState(false);
  const { setModalContent } = useContext(LayoutContext);

  const handleCtaClick = () => {
    setModalContent(ActNowModal);
  };

  const getImageByName = (name) => {
    return pageImages.find((e) => e.name === name);
  };

  const heroSection = pageLabels.hero;
  const beeSection = pageLabels.beeSection;
  const yourBrandSection = pageLabels.yourBrand;
  const preTech = pageLabels.preTechSlider;
  const technologySliderSection = pageLabels.TechnologySlider;
  const techSection = pageLabels.tech;
  const techTwoSection = pageLabels.techTwo;
  const growerSection = pageLabels.grower;
  const communitySection = pageLabels.community;
  const educationSection = pageLabels.education;
  const videoSection = pageLabels.video;
  const bottomBeeSection = pageLabels.bottomBeeSection;

  return (
    <div>
      <Meta />
      <Hero
        heroImage={getImageByName("api-overlay")}
        onCtaClick={handleCtaClick}
        content={heroSection}
      />
      <BeeSection content={beeSection} video={beeSection.videoUrl}/>
      <LinksSection />
      <GridSection
        id={yourBrandSection?.id}
        header={yourBrandSection?.header}
        items={yourBrandSection?.items}
        footer={yourBrandSection?.footer}
        isBackgroundChanging={background}
        onCtaClick={handleCtaClick}
        setBackground={setBackground}
        triggerBackground
      />
      <WrapperBackGroundChanging
        preTechContent={preTech.content}
        techSliderContent={technologySliderSection}
        techId={techSection?.id}
        techHeader={techSection?.header}
        techItems={techSection?.items}
        techFooter={techSection?.footer}
        background={background}
        setBackground={setBackground}
        techTwoHeader={techTwoSection?.header}
        techTwoItems={techTwoSection?.items}
        techTwoFooter={techTwoSection?.footer}
        techAudio={techTwoSection?.audioSrc}
        onCtaClick={handleCtaClick}
      />

      <GridSection
        id={growerSection?.id}
        header={growerSection?.header}
        items={growerSection?.items}
        footer={growerSection?.footer}
        isBackgroundChanging={background}
        onCtaClick={handleCtaClick}
        dots={"left"}
      />

      <GridSection
        id={communitySection?.id}
        header={communitySection?.header}
        items={communitySection?.items}
        footer={communitySection?.footer}
        onCtaClick={handleCtaClick}
        dots={"right"}
      />

      <GridSection
        id={educationSection?.id}
        header={educationSection?.header}
        items={educationSection?.items}
        footer={educationSection?.footer}
        onCtaClick={handleCtaClick}
        dots={"left"}
        lastSection
      />

      <VideoSection content={videoSection} />

      <BottomBeeSection
        content={bottomBeeSection}
        onCtaClick={handleCtaClick}
      />
    </div>
  );
};

export default Originals;
