import React, { useEffect }  from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { heroBottom, staggerChildren } from "../animations";
// import { StyledBigText } from "../../../commons/Typography";
import {
  StyledFlower,
  StyledHeroCta,
  StyledOriginalsSubtitleHero,
  StyledOriginalsTitle,
  StyledWrapper,
  MotionBigText,
  StyledWrapperVideo,
  StyledGradient,
  StyledWrapperButton,
} from "./style";

export default ({ heroImage, onCtaClick, content }) => {
  const [ref, inView] = useInView({ threshold: 0.1 });
  const startAnimate = useAnimation();
  useEffect(() => {
    if (inView ) {
      startAnimate.start("onscreen");
    }
  }, [inView]);
  return (
    <StyledWrapper
      ref={ref}
      heroImage={heroImage.path}
      initial="offscreen"
      // whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      variants={staggerChildren}
      animate={startAnimate}
    >
      <StyledWrapperVideo>
        <video muted autoPlay playsinline webkit-playsinline loop controls={false}>
          <source src={content.videoUrl} type="video/mp4" />
        </video>
        <StyledGradient />
      </StyledWrapperVideo>
      <StyledFlower variants={heroBottom} />
      <StyledOriginalsTitle
        variants={heroBottom}
        dangerouslySetInnerHTML={{ __html: content.title }}
      />

      <MotionBigText
        variants={heroBottom}
        dangerouslySetInnerHTML={{ __html: content.bigText }}
      />
      <StyledOriginalsSubtitleHero
        variants={heroBottom}
        dangerouslySetInnerHTML={{ __html: content.subtitle }}
      />

      <StyledWrapperButton variants={heroBottom}>
        <StyledHeroCta onClick={onCtaClick}>{content.ctaLabel}</StyledHeroCta>
      </StyledWrapperButton>
    </StyledWrapper>
  );
};
