import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer"; 
import TechnologySlider from "../TechnologySlider/TechnologySlider";
import GridSection from "../GridSection/GridSection";
import PreTech from "../PreTech/PreTech";
import {
  StyledWrapperComponents,
  StyledGradient,
} from "./style";
import {fadeOnBottom } from "../animations";

export default (props) => {
  const {
    preTechContent,
    techSliderContent,
    techId, 
    techHeader, 
    techItems, 
    techFooter,
    background,
    setBackground,
    techTwoHeader,
    techTwoItems,
    techTwoFooter,
    techAudio,
    onCtaClick
  } = props
  const [ref, inView] = useInView({ threshold: 0.1 });
  const [isWrapperActive, setIsWrapperActive] = useState(false);
  
  useEffect(() => {
    if (inView ) {
      setBackground(true)
      setIsWrapperActive(true)
    } else {
      setBackground(false)
      setIsWrapperActive(false)
    }
  }, [inView]);

  return (
    <StyledWrapperComponents
      ref={ref} 
      background={background}
    >
      <PreTech ref={ref} preTechContent={preTechContent} background={background} setBackground={setBackground} variants={fadeOnBottom} isWrapperActive={isWrapperActive} />
      <TechnologySlider
        content={techSliderContent}
        background={background}
      />
      <GridSection
        id={techId}
        header={techHeader}
        items={techItems}
        footer={techFooter}
        isBackgroundChanging={background}
        onCtaClick={onCtaClick}
      />
      <GridSection
        header={techTwoHeader}
        items={techTwoItems}
        footer={techTwoFooter}
        audioSrc={techAudio}
        isBackgroundChanging={true}
        onCtaClick={onCtaClick}
      />
      <StyledGradient />
    </StyledWrapperComponents>
  );
};
