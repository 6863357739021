import React, { useContext, useEffect } from "react";
import { StyledBeeSectionTitle, StyledTechnologySectionSubTitle, TechsWrapper, StyledWrapperImage, StyledWrapperSwiper } from "./style.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { fadeLinkOnBottom, fadeOnBottom, staggerChildren } from "../animations";
import { useInView } from "react-intersection-observer"; 
import { useAnimation } from "framer-motion";

export default ({content, background}) => {

  const isMobile = useCheckMobileScreen();

  const [ref, inView] = useInView({ threshold: 0.1 });
  const startAnimate = useAnimation();
  useEffect(() => {
    if (inView) {
      startAnimate.start("onscreen")
    } else if (!background) {
      startAnimate.start("offscreen")
    }
  }, [inView]);

  return (
    <TechsWrapper 
      id={content.id}
      initial="offscreen"
      // whileInView="onscreen"
      animate={startAnimate}
      viewport={{ once: true, amount: 0.8 }}
      variants={staggerChildren}
      ref={ref}
    >
      <StyledBeeSectionTitle variants={fadeOnBottom} dangerouslySetInnerHTML={{ __html: content.title }}/>
      <StyledTechnologySectionSubTitle variants={fadeOnBottom} dangerouslySetInnerHTML={{ __html: content.paragraph }}/>
      <StyledWrapperSwiper>
        <Swiper
          slidesPerView={!isMobile ? 3 : 2}
          spaceBetween={!isMobile ? 70 : 50}
          className="technologySwiper"
          loop={true}
          loopFillGroupWithBlank={true}
          centeredSlides={true}
          grabCursor={!isMobile ? true : false}
        >
          {
            content.sliderListImage.map((item, index) => (
                <SwiperSlide key={index}>
                  <StyledWrapperImage variants={fadeLinkOnBottom}>
                    <img src={item.img} alt={item.alt} />
                  </StyledWrapperImage>
                </SwiperSlide>
            ))
          }
        </Swiper>
      </StyledWrapperSwiper>
    </TechsWrapper>
  );
};
