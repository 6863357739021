import React from "react";
import { Helmet } from "react-helmet";
import {
  StyledVideoSectionContainer,
  StyledVideoSectionDescription,
  StyledVideoSectionTitle,
} from "./style";

export default ({ content }) => {
  return (
    <StyledVideoSectionContainer>
      <StyledVideoSectionTitle
        dangerouslySetInnerHTML={{ __html: content.title }}
      />
      <StyledVideoSectionDescription
        dangerouslySetInnerHTML={{ __html: content.description }}
      />

      <div style="padding:56.25% 0 0 0;position:relative;">
        <iframe
          src={content.url}
          style="position:absolute;top:0;left:0;width:100%;height:100%;"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <Helmet>
        <script src="https://player.vimeo.com/api/player.js" />
      </Helmet>
    </StyledVideoSectionContainer>
  );
};
